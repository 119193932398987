/* This file intentionally left blank.
 * Put your custom CSS into your (not the theme's) assets/sass/custom.scss
 */

div.subtitle {
  display: flex;
  align-items: center;
}

ul.subtitle {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}
ul.subtitle li {
  float: left;
}

img.language {
  margin-left: 10px;
  margin-right: 5px;
}

ul.seemelive {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

ul.seemelive .date {
		display: block;
		position: absolute;
		left: 0;
		top: 1.3em;
		background-color: _palette(accent, bg);
		height: 3.5em;
		text-align: center;
		color: #fff;
		line-height: 1em;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		padding: 0.5em 0.75em 0 1em;
}

ul.seemelive .date:after {
  content: '';
	position: absolute;
	bottom: 0;
	right: -1.2em;
	border-left: solid 1.25em _palette(accent, bg); 
	border-top: solid 1.8em transparent;
	border-bottom: solid 1.8em transparent;
}

.past {
 text-decoration: line-through;
}

ul.seemelive .date strong {
  display: block;
  font-size: 1.75em;
  padding-top: 0.15em;
}

ul.seemelive h3 {
  font-size: 1.1em;
  margin-bottom: 0;
}

ul.seemelive p {
  margin: 0;
}

ul.seemelive li:first-child {
  border-top: 0;
	padding-top: 0;
}

ul.seemelive li:first-child .date {
  top: 0;
}

ul.seemelive li {
  list-style-type: none;
  position: relative;
  border-top: solid 1px #ddd;
  padding: 1.3em 0 1.3em 6.75em;
  border-top-color: #353434;
  border-top-color: rgba(255, 255, 255, 0.05);
}

ul.seemelive li p {
  display: block;
  margin: 0;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

/* Pagination */
ul.pagination li:last-child {
  position: absolute;
  top: 0;
  right: 0;
}
ul.pagination {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
ul.pagination li {
  display: flex;
  margin: 1px;
  padding: 5px;
}

$primary-color: #4CAF50;
$border-color: #ccc;
$checkmark-color: white;
$checkbox-size: 20px;

input[type="checkbox"] {
  appearance: auto;
  // Hide the default checkbox
  //opacity: 0;
  //position: absolute;

  // Custom checkbox
  & + label {
    position: relative;
    cursor: pointer;
    padding-left: calc(#{$checkbox-size} + 10px);
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: $checkbox-size;
      height: $checkbox-size;
      border: 2px solid $border-color;
      border-radius: 3px;
      background-color: white;
      margin-right: 10px;
      transition: background-color 0.3s, border-color 0.3s;
    }
  }

  // Checked state
  &:checked + label:before {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  // Checkmark
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid $checkmark-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
blockquote {
  border-left: solid 0.3em #e0e0e0;
  padding: 0.1em 0 0.1em 0em;

    p {
        display: inline;

        &:first-of-type {
            // Quote symbols
            quotes: '\201C' '\201D' '\2018' '\2019';

            &::before {
                content: open-quote;
                margin-right: 0.1rem;
            }

            &::after {
                content: close-quote;
                margin-left: 0.1rem;
            }
        }

        &:last-of-type {
            // Apply styles for the source/author paragraph
            font-style: italic;
            display: block;
            margin-top: 1rem; /* Adjust this value to get the desired space above */
            text-indent: 2em; /* Indent the second paragraph */

            &::before {
                // \2014 is the unicode em dash character
                content: "\2014" " ";
                margin-left: 0.1rem;
                font-style: normal;
            }
        }

        // In case of a quote without a source, with only one p
        &:only-of-type {
            display: block;
            font-style: normal;
            quotes: '\201C' '\201D' '\2018' '\2019';

            &::before {
                content: open-quote;
                margin-right: 0.1rem;
            }

            &::after {
                content: close-quote;
                margin-left: 0.1rem;
            }
        }
    }
}
