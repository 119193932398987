// Misc.
    $misc: (
        z-index-base:   10000
    );

// Duration.
    $duration: (
        navPanel: 0.5s
    );

// Size.
    $size: (
        navPanel: 275px,
        radius: 5px
    );

// Font.
    $font: (
        weight: 600
    );

// Palette.
    $palette: (
        bg: #FAFAFA,
        fg: #333333,
        fg-bold: #4c4c4c,
        fg-light: #999,
        border: #e0e0e0,

        accent: (
            bg: #37c0fb,
            fg: #FAFAFA
        )
    );
